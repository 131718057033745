// Theme colours
$athens-grey:     #ebebec;    // rgb(235,235,236)
$battleship-grey: #6b6d88;    // rgb(107,109,136)
$cerulean:        #0379c5;    // rgb(  3,121,197)
$charcoal-grey:   #32343f;    // rgb( 50, 52, 63)
$cosmic-purple:   #77385c;    // rgb(119, 56, 92)
$dark-pink:       #c02b80;    // rgb(192, 43,128)
$french-grey:     #b6b6c4;    // rgb(182,182,196)
$light-mauve:     #d9cfd6;    // rgb(217,207,214)
$manatee-mauve:   #85869c;    // rgb( 33,134,156)
$pale-grey:       #f5f8fa;    // rgb(245,248,250)
$soft-green:      #61ba96;    // rgb( 97,186,150)
$white:           #ffffff;    // rgb(255,255,255)

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f3f3f3 !important;
}

@font-face {
  font-family: DINNextLTPro-Regular;
  src: url('./DINNextLTPro-Regular.otf');
}

.loader,
.loader:before,
.loader:after {
  background: #6b6d88;
  -webkit-animation: loading 1s infinite ease-in-out;
  animation: loading 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader {
  color: #6b6d88;
  text-indent: -9999em;
  margin: 200px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
